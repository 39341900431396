import React, { Suspense } from 'react';

import ReactDOM from 'react-dom/client';
import App from './App';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import ErrorBoundary from './compoents/ErrorBoundary';
import '../src/index.css'
import ThemeProvider from 'react-bootstrap/ThemeProvider';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';


Sentry.init({
  dsn: "https://d03af9f831d24f6485355b4b15364814@o4504199646216192.ingest.sentry.io/4504199650934784",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
  <I18nextProvider i18n={i18n}>

  <Suspense>

  <Sentry.ErrorBoundary>
  <ErrorBoundary>
  <ThemeProvider dir={i18n.dir}>

<App />
    </ThemeProvider>
    </ErrorBoundary>
    </Sentry.ErrorBoundary>
    </Suspense>
</I18nextProvider>

);

