import { useState } from 'react';

export default function useLang() {
  const getLang = () => {
    const langString = localStorage.getItem('lang');
    const userlang = langString;
    const languages = ['ar','en'];
    if(languages.includes(userlang) === true){
      return 'ar';
    }
    return 'ar'
  };
  console.log(getLang());
  const [lang, setLanguage] = useState(getLang());
  const saveLang = userLang => {

    localStorage.setItem('lang', userLang);
   
    setLanguage(userLang);
  };

  return {
    setLanguage: saveLang,
    lang
  }
}