import axios from 'axios';
import React, { useState } from 'react'
import OtpInput from 'react18-input-otp';
import './otp.css'
import { Button, message, Space } from 'antd';
import * as CONSTANTS from '../../CONSTANTS'
import { useTranslation } from 'react-i18next';


export default function Otp ({setToekn , token}) {
    const [otp, setOtp] = useState('');
    const {t} = useTranslation('home');

    const validateOtp = async (otpCode , token) => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+token
          }
          return     await   axios.get(CONSTANTS.DEFAULT_URL+'otps?filters[code][$eq]='+otpCode , {
            headers: headers
          })
         
    }

    const resendOtpApi = async (token) => {
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+token
          }
          return     await   axios.post(CONSTANTS.DEFAULT_URL+'otp/resend' , {},{
            headers: headers
          })
         
    }

    const [messageApi, contextHolder] = message.useMessage();

    const showMessage = (message  ,type) => {
        messageApi.open({
          type: type,
          content: message,
        });
      };
const checkOtp = async ()=>{
    if(otp === ''){
        showMessage('Otp is empty','error');
          return false;
    }
   await validateOtp(otp , token).then(function (response) {
        // handle success
        window.location = '/';

        console.log(response);
        
        }).catch(function (error) {
           
            console.log(error.response.data.error.message);
            if(error.response.data.error.message !== null){
                showMessage(error.response.data.error.message,'error');

            }
return false;

            
            })
            .finally(function () {
            });

   
}
const resendOTp = async ()=>{
   
   await resendOtpApi( token).then(function (response) {
        // handle success
        showMessage('We have send you a new code','success');

        return false;
        
        }).catch(function (error) {
            // handle error
            //  setToken(null);
            console.log(error.response.data.error.message);
            if(error.response.data.error.message !== null){
                showMessage(error.response.data.error.message,'error');

            }
return false;

            
            })
            .finally(function () {
            });

   
}
  
  return (
   <>
         {contextHolder}

   <div class="col-md-6 offset-3">
                        <div class="onboarding-content-box content-wrap">
                            <div class="onboard-set">
                                <div class="onboarding-title">
                                    <h2>{t('Enter 6-digit code sent to your phone.')}</h2>
                                    {/* <h6>We’ve sent it to XXXXXXXXX784</h6> */}
                                </div>
                                <div class="onboarding-content passcode-wrap" style={{"direction":"ltr"}}>
                                <OtpInput inputStyle={'co_input'} value={otp} onChange={(e)=>setOtp(e)}
                                 numInputs={6} separator={<span> </span>} />
                                </div>
                                <div class="opt-resend">
                                    <a href="#" onClick={resendOTp} class="text-danger">{t('Resend OTP')}</a>
                                </div>
                            </div>
                            <div class="onboarding-btn">
                                <a href="#" onClick={checkOtp}>{t('Verfiy')}</a>
                            </div>
                        </div>
                    </div></>
  )
}
