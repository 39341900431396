import { message, Space } from 'antd';
import Skeleton  from 'antd/es/skeleton';
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { check_auth } from '../../helper'
import i18n from '../../i18n';
import { DashboradNavBar } from './DashboradNavBar'
import * as CONSTANTS from '../../CONSTANTS'
import axios from 'axios';

export const Dashboard = ({token }) => {
	const [load,setLoading] = useState(false);
	const [upComingVisit,setUpComingVisit] = useState(0);
	const {t} = useTranslation('home');
		const [cancelledOrdersNum,setCancelledOrdersNum] = useState(0);
	const [totalRev,setTotalRev] = useState(0);
	const [totalRevToday,setTotalRevToday] = useState(0);
    const [appointmentsLoad , setAppointmentsLoading] = useState(false);
    const [key , setKey] = useState(0);

    const [userData , setData] = useState();
	const [profilePic, setProfilePic] = useState(null);
    const [appointments , setAppointmentsData] = useState([]);
	const [messageApi, contextHolder] = message.useMessage();
    const tableRef = useRef(null);
	const [completedVisit,setUpCompletedVisit] = useState(0);

 const getUser = async ()=>{
	return await check_auth(token);
 }	

 useEffect(() => {
	getUser().then((e)=>{
		if(e.data.type !== 'polyclinic'){
              localStorage.removeItem('token');
			            window.location = "/ar/login";

		}



		
		setUpCompletedVisit(e.data.completed_visites);

		setUpComingVisit(e.data.upcoming_visits.length);
		setCancelledOrdersNum(e.data.cancelledOrders);
		setTotalRev(e.data.totalRevnue);
		setTotalRevToday(e.data.totalRevresToday);
		setAppointmentsData(e.data.upcoming_visits);

		setAppointmentsLoading(true);

		setLoading(true)

	}).catch((e)=>{
		console.log('error',e);
          window.location = "/ar/login";
	});
 }, [key])
 const update_state = async (order_id ,status)=>{
	setLoading(true);

	const headers = {
		'Content-Type': 'application/json',
		'Authorization': 'Bearer '+token
	  }
await	axios.put(CONSTANTS.DEFAULT_URL+'polyclinic/updateAppointMentStatus/'+order_id , {
		"data": {
			"status":status
		}
	},{headers : headers} )
.then(function (response) {
	setKey(Math.random());
	message.success('تم التحديث')

	// setLoading(false);
}).catch((error)=>{

	message.error(error.response.data.error.message);
	// setLoading(false);


});
}
 const get_patient_image = (data)=>{
	if(data.patient.data!== null){
		return data.patient.profile.maritalStatus;

	}
}

	const get_status = (data)=>{

		if(data.appointment.status !== null){
		return data.appointment.status;
	
		}
	}
		const get_timeSlot = (data)=>{

		if(data.appointment.timeslot !== null){
		return data.appointment.timeslot.start;
	
		}
	}
	const get_fees = (data)=>{

		if(data.totalPrice !== null){
		return data.totalPrice;
	
		}
	}
	const get_payment = (data)=>{

		if(data.paymentStatus !== null){
		return data.paymentStatus;
	
		}
	}
	const get_booking_date = (data)=>{

		if(data.date !== null){
			var date = new Date(data.date);

		return date.toLocaleDateString('ar-EG-u-nu-latn',{weekday: 'long', year: 'numeric', month: 'short', day: 'numeric'});

		}
	}
	const get_order_data = (data)=>{
		var d = [];

	  if(data.appointment.doctor_service !== null){
		  var type =  data.appointment.doctor_service.serviceType;
		  var consultationDuration = data.appointment.doctor_service.consultationDuration
	   if(type === 'telehealth'){
		  d.push(t('TeleHealth'));
		  d.push(" ("+consultationDuration+" "+t("Minute")+")");

	   }else if(type === 'clinic'){
		d.push(t('Clinic'));

	}else if(type === 'homevisit'){
		d.push(t('Home Visit'));

	   }
		  }
		  return d;
  }
  const get_patient_name = (data)=>{
	if(data.patient !== null ){
		if(i18n.language === 'ar' && data.patient.profile.firstname_ar !== null && data.patient.profile.firstname_ar !== ''){
			return (data.patient.profile.firstname_ar + ' '+data.patient.profile.lastname_ar);

		}
        return (data.patient.profile.firstname + ' '+data.patient.profile.lastname);

	}
}
const get_patient_whatsnumber = (data)=>{
	if(data.patient !== null){
        return (data.patient.profile.whatsappNumber);

	}
}
const convertToEgyTime = (datatime) => {
	const timeSlotDatetime = new Date(datatime).getTime();
	const newTimeSlot = new Date(timeSlotDatetime + 2 * 60 * 60 * 1000);
	newTimeSlot.setHours(newTimeSlot.getHours() - 2);

	return newTimeSlot.toLocaleTimeString();
  };
  const ConvertToArabicNumbers = (num) => {
	if(i18n.language === 'ar'){
		const arabicNumbers = '\u0660\u0661\u0662\u0663\u0664\u0665\u0666\u0667\u0668\u0669';
		return new String(num).replace(/[0123456789]/g, (d)=>{return arabicNumbers[d]}).replace("PM","مساءا").replace("AM","صباحا");
	   
	}
	return num;
}
  return (
<>{load ? (<>
	<div class="col-md-7 col-lg-8 col-xl-9">
		<div class="row">
			
			<div class="col-lg-4 col-md-12">
				<div class="card dash-cards">
					<div class="card-body">
						<div class="dash-top-content">
							<div class="circle-bar circle-bar2">
								<div class="circle-graph2" data-percent="75">
									<img src="assets/img/icon-03.png" class="img-fluid" alt="patient" />
								</div>
							</div>
							<div class="dash-widget-info">
								<h6>{t('Total UpComing Visit')}</h6>
								<h3>{upComingVisit}</h3>
							</div>
						</div>
					
					</div>
				</div>
			</div>
			<div class="col-lg-4 col-md-12">
				<div class="card dash-cards">
					<div class="card-body">
						<div class="dash-top-content">
							<div class="circle-bar circle-bar2">
								<div class="circle-graph2" data-percent="75">
									<img src="assets/img/icon-03.png" class="img-fluid" alt="patient" />
								</div>
							</div>
							<div class="dash-widget-info">
								<h6>{t('Total Orders')}</h6>
								<h3>{completedVisit+cancelledOrdersNum}</h3>
							</div>
						</div>
					
					</div>
				</div>
			</div>
			<div class="col-lg-4 col-md-12">
				<div class="card dash-cards">
					<div class="card-body">
						<div class="dash-top-content">
							<div class="circle-bar circle-bar2">
								<div class="circle-graph2" data-percent="75">
									<img src="assets/img/icon-03.png" class="img-fluid" alt="patient" />
								</div>
							</div>
							<div class="dash-widget-info">
								<h6>{t('Total Completed Orders')}</h6>
								<h3>{completedVisit}</h3>
							</div>
						</div>
					
					</div>
				</div>
			</div>
			<div class="col-lg-4 col-md-12">
				<div class="card dash-cards">
					<div class="card-body">
						<div class="dash-top-content">
							<div class="circle-bar circle-bar2">
								<div class="circle-graph2" data-percent="75">
									<img src="assets/img/icon-03.png" class="img-fluid" alt="patient" />
								</div>
							</div>
							<div class="dash-widget-info">
								<h6>{t('Total Cancelled Orders')}</h6>
								<h3>{cancelledOrdersNum}</h3>
							</div>
						</div>
					
					</div>
				</div>
			</div>
			<div class="col-lg-4 col-md-12">
				<div class="card dash-cards">
					<div class="card-body">
						<div class="dash-top-content">
							<div class="circle-bar circle-bar3">
								<div class="circle-graph3" data-percent="50">
									<img src="assets/img/icon-4.png" class="img-fluid" alt="patient" />
								</div>
							</div>
							<div class="dash-widget-info">
								<h6>{t('Today’s Income')}</h6>
								<h3>{totalRevToday ?? 0} {t('pound')}</h3>
							</div>
						</div>
						
					</div>
				</div>
			</div>
			<div class="col-lg-4 col-md-12">
				<div class="card dash-cards">
					<div class="card-body">
						<div class="dash-top-content">
							<div class="circle-bar circle-bar3">
								<div class="circle-graph3" data-percent="50">
									<img src="assets/img/icon-4.png" class="img-fluid" alt="patient" />
								</div>
							</div>
							<div class="dash-widget-info">
								<h6>{t('Total Income')}</h6>
								<h3>{totalRev ?? 0} {t('pound')}</h3>
							</div>
						</div>
						
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12">
				<div class="card dash-cards">
					<div class="card-header">
						<div class="today-appointment-title">
							<h5 class="mb-0">{t('Appointments')}</h5>
							<a href="appointments.html"><h6 class="mb-0">{t('View All')} <i class="feather-arrow-right"></i></h6></a>
						</div>
					</div>
					<div class="card-body">
						<div class="table-responsive">
						<table class="table table-hover table-center mb-0" ref={tableRef} id="datatable"> 
												<thead>
													<tr>
														<th>{t('Patient')}</th>
														<th>{t('Order No.')}</th>

														<th>{t('Booking Date')}</th>
														<th>{t('Total')}</th>
														<th>{t('Order Data')}</th>
														<th>{t('Time')}</th>

														<th>{t('Payment Status')}</th>

														<th>{t('Status')}</th>
														<th></th>
													</tr>
												</thead>
												<tbody>
				{appointmentsLoad ? (
appointments.length > 0 ? (

	appointments.map((appointment, i) => {
	 
	  return (
		appointment.appointment != null ? <>	
		
					
													<tr>
														<td>

															<h2 class="table-avatar">
																{/* <a href="#" class="avatar avatar-sm me-2">
																	<img class="avatar-img rounded-circle" src={get_patient_image(appointment)} alt={get_patient_name(appointment)} />
																</a> */}
																<a href="#">{get_patient_name(appointment)} </a>
															</h2>
														</td>
														 <td>#{appointment.id}</td>

														<td>{ConvertToArabicNumbers(get_booking_date(appointment))}</td>
														<td>{ConvertToArabicNumbers(get_fees(appointment))} {t('pound')}</td>
													<td>{get_order_data(appointment)}</td>
													<td>{ConvertToArabicNumbers(convertToEgyTime(get_timeSlot(appointment)))}</td>

													{/* <td></td> */}
														<td>{t(get_payment(appointment))}</td>

														<td>{t(get_status(appointment))}</td>
														<td class="text-end">
															<div class="table-action">
															<Space>
															<Link to={"/"+i18n.language+"/appointment/"+appointment.id}  class="btn btn-sm bg-info-light">
																 {t('View')} 
																</Link>
													{get_status(appointment) !== 'completed'	?	(	<>	<a href="#" onClick={()=>{update_state(appointment.id,'cancelled')}}  class="btn btn-sm bg-danger-light">
																 {t('Cancel')}
																</a>
																
																
																<a href="#"  class="btn btn-sm bg-danger-light" onClick={()=>{update_state(appointment.id,'rescheduled')}}>
																{t('Rescheduled')}
															   </a>

{/* <a href="#" onClick={()=>{update_state(appointment.id,'scheduled')}}  class="btn btn-sm bg-danger-light">
{t('scheduled')}
</a> */}
</>
																
																
																) : (<></>) }
																</Space>
															</div>
														</td> 
													</tr>
							
			
	</>: <></>
				
	   )
	}
	)
	) : (
	  <>No Doctors</>
	)
  ) : (
	<>
	<Skeleton  avatar
paragraph={{
rows: 4,
}} active />
   </>
  )}
					
									
					</tbody>
											</table>
						</div>
					</div>
				</div>
			</div>
		
		</div>
	
	</div>
	</>):(<div class="col-md-7 col-lg-8 col-xl-9"><Skeleton /></div>)}	
	</>
  )
}
