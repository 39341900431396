import { Divider, message } from 'antd';
import Skeleton  from 'antd/es/skeleton';
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { check_auth } from '../../helper'
import i18n from '../../i18n';
import { DashboradNavBar } from './DashboradNavBar'
import * as CONSTANTS from '../../CONSTANTS'
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import  LoadingOutlined from '@ant-design/icons/es/icons/LoadingOutlined';
import  Upload  from 'antd/es/upload';
import Select from 'antd/es/select';

export const AppointmentPage = ({token }) => {

	const [load,setLoading] = useState(false);
	const [upComingVisit,setUpComingVisit] = useState(0);
	const {t} = useTranslation('home');
		const [cancelledOrdersNum,setCancelledOrdersNum] = useState(0);
	const [totalRev,setTotalRev] = useState(0);
	const [totalRevToday,setTotalRevToday] = useState(0);
    const [appointmentsLoad , setAppointmentsLoading] = useState(false);

    const [userData , setData] = useState();
	const [profilePic, setProfilePic] = useState(null);
    const [appointment , setAppointmentData] = useState();
	const [messageApi, contextHolder] = message.useMessage();
    const tableRef = useRef(null);
	const [uploadId, setUploadId] = useState(null);
    const [prescriptionText,setPrescriptionText] = useState('');
	const [timeslot, setTimeSlot] = useState(null);
	const [loading, setLoadings] = useState(false);
    const [patientId, setpatientId] = useState(null);
	const [code, setCode] = useState(null);
    const [redeemedPoints, setRedeemedPoints] = useState(null);
    const [doctorId, setDoctorId] = useState(null);

    const { Option } = Select;

	const AddPharmacyOrder = async (type) => {
		if(uploadId === null || uploadId === undefined || uploadId === ''){
message.error("ارفع الروشته اولا");
return false;
		}

       
        const check_auth_response = await check_auth(token);
        let items = [];
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }

  let data = {
	'newUser': false,
	'promoCode': code,
	'redeemedPoints': redeemedPoints ? redeemedPoints : 0,
	'paymentType': 'post_paid',
	'patient': patientId,
	 'doctor':doctorId,
	'orderFrom': 'polyclinic',
	"polyclinic":polyclinic,
	'type': type,
	'items': [],
	'location': 'lab',
	'note':'',
	'totalPrice':null,
	'prescription':[uploadId]


};

// |     promoCode: '',
// 0|doxx  |     redeemedPoints: null,
// 0|doxx  |     location: 'lab',
// 0|doxx  |     paymentType: 'post_paid',
// 0|doxx  |     patient: 987,
// 0|doxx  |     type: 'lab',
// 0|doxx  |     address: 7301,
// 0|doxx  |     items: [],
// 0|doxx  |     prescription: [ 5613 ],
// 0|doxx  |     orderFrom: 'patient',
// 0|doxx  |     note: '',
// 0|doxx  |     totalPrice: null,
// 0|doxx  |     supplier: 5

if(type === "lab"){
 data.supplier = lab;
 if(lab === 0 || lab === undefined || lab === null || lab === ''){
  message.error("اختر موزع اولا");
  return false;
 }
}

if(type === "scan"){
	data.supplier = scan;
	if(scan === 0 || scan === undefined || scan === null || scan === ''){
		message.error("اختر موزع اولا");
		return false;
	   }
   }
   if(type === "pharmacy"){
	data.supplier = null;
	
   }

        await fetch(CONSTANTS.DEFAULT_URL + 'orders', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token

            },
			


            ///api/patients?locale=ar&filters%5Bprofile%5D%5Buser%5D%5BmobileNumber%5D%5B%24contains%5D=%2B201062328373&populate=profile
            body: JSON.stringify({
                "data": data
            })
        }).then(async(response) => {
			response =await response.json();
			if(response.errorMessage){
                message.error(response.errorMessage);
				return false;

			}

			if(response.id){
				message.success("تم الطلب بنجاح");

			}
        }).catch((error) => {
            message.error(error.message);

        });



    }

	const onTimeSlotChange = (e, timeslot) => {

		e.stopPropagation();
		e.preventDefault();
		if (document.getElementsByClassName("timing selected").length > 0) {
			document
			  .getElementsByClassName("timing selected")[0]
			  .classList.remove("selected");
	
		  }
		e.target.classList.add("selected");
		setTimeSlot(timeslot.id);
	   
	
	   
	  };
    const params = useParams();

 const getAppointment = async ()=>{
    try {

		let response = await  fetch(CONSTANTS.DEFAULT_URL+'polyclinic/appointment/'+params.id, {
		  method: 'GET',
		  headers: {
			'Content-Type': 'application/json' ,
			'Authorization': 'Bearer '+token

		  },
		  
		})
		let json = await response.json();
		console.log(json);
		if(response.status !== 200){
			//   window.location = "/"+i18n.language+""
		}
	
			return { success: true, data: json };
	
		
	
	} catch (error) {
		return { success: false };
	  }

 }	
 const updateTimeSlot = async ()=>{
	if(timeslot == null || timeslot == undefined){
		message.error('اختر موعد اولا');
         return false;
	}
    try {

		let response = await  fetch(CONSTANTS.DEFAULT_URL+'polyclinic/updateTimeSlot/'+params.id, {
		  method: 'POST',
		  headers: {
			'Content-Type': 'application/json' ,
			'Authorization': 'Bearer '+token

		  },
		  body: JSON.stringify({
		    "data":{
				"timeslot":timeslot,
			}
		})
		})
		let json = await response.json();
		console.log(json);
		if(response.status == 200){
			await	update_state(params.id,'rescheduled');

			//   window.location = "/"+i18n.language+""
			message.success("تم تغير الموعد");
			setTimeout(() => {
				window.location.reload();

			}, 1000);

		}
	
			return { success: true, data: json };
	
		
	
	} catch (error) {
		return { success: false };
	  }

 }	
 
 const [fileList, setFileList] = useState([]);

	const [scans, setScans] = useState([]);
	const [dataLoaded, setDataLoaded] = useState(false);
	const [labs, setLabs] = useState([]);
	const [lab, setLab] = useState(null);
	const [scan, setScan] = useState(null);

	const [error, setError] = useState(null);
	const [polyclinic, setPolyclinicId] = useState(null);

    const navigate = useNavigate();
 useEffect(() => {
	if(token != null ){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+token
          }
        axios.get(CONSTANTS.DEFAULT_URL+'users/me' , {
            headers: headers
          })
.then(function (response) {
    setPolyclinicId(response.data.polyclinic.id);
  


       if(response.status === 200){
        setData(response.data);


       }else{
        navigate('/'+i18n.language+'/login/doctor');
       }
    

})
.catch(function (error) {
    navigate('/'+i18n.language+'/login');


if(error.response.status === 403){
    navigate('/'+i18n.language+'/login');


}
  console.log(error);
})
// .finally(function () {
//     setLoading(false);
// });
}else{
navigate('/'+i18n.language+'/login');


}
	axios(CONSTANTS.DEFAULT_URL+"suppliers?filters[$and][0][type][$eq]=lab")
	.then((labResponse) => {
		setDataLoaded(true);

		setLabs(labResponse.data.data);
		setLab(labResponse.data.data[0].id);
		
	})
	.catch(setError);

	axios(CONSTANTS.DEFAULT_URL+"suppliers?filters[$and][0][type][$eq]=scan")
	.then((labResponse) => {
		setDataLoaded(true);

		setScans(labResponse.data.data);
		setScan(labResponse.data.data[0].id);
		
	})
	.catch(setError);



getAppointment().then((e)=>{

  setAppointmentData(e.data.order);
  setpatientId(e.data.order.patient.id);
  setDoctorId(e.data.order.doctor?.id);

  setAppointmentsLoading(true);

  setLoading(true)
})




	
 }, [])
 const get_patient_image = (data)=>{
	if(data.patient.data!== null){
		return data.patient.profile.maritalStatus;

	}
}

	const get_status = (data)=>{

		if(data.appointment.status !== null){
		return data.appointment.status;
	
		}
	}
		const get_timeSlot = (data)=>{

		if(data.appointment.timeslot !== null){
		return data.appointment.timeslot.start;
	
		}
	}
	const get_fees = (data)=>{

		if(data.totalPrice !== null){
		return data.totalPrice;
	
		}
	}
	const get_payment = (data)=>{

		if(data.paymentStatus !== null){
		return data.paymentStatus;
	
		}
	}
	const get_booking_date = (data)=>{

		if(data.date !== null){
			var date = new Date(data.date);

		return date.toLocaleDateString('ar-EG-u-nu-latn',{weekday: 'long', year: 'numeric', month: 'short', day: 'numeric'});

		}
	}
	const get_order_data = (data)=>{
		var d = [];

	  if(data.appointment.doctor_service !== null){
		  var type =  data.appointment.doctor_service.serviceType;
		  var consultationDuration = data.appointment.doctor_service.consultationDuration
	   if(type === 'telehealth'){
		  d.push(t('TeleHealth'));
		  d.push(" ("+consultationDuration+" "+t("Minute")+")");

	   }else if(type === 'clinic'){
		d.push(t('Clinic'));

	}else if(type === 'homevisit'){
		d.push(t('Home Visit'));

	   }
		  }
		  return d;
  }
  const get_patient_name = (data)=>{
	if(data.patient !== null ){
		if(i18n.language === 'ar' && data.patient.profile.firstname_ar !== null && data.patient.profile.firstname_ar !== ''){
			return (data.patient.profile.firstname_ar + ' '+data.patient.profile.lastname_ar);

		}
        return (data.patient.profile.firstname + ' '+data.patient.profile.lastname);

	}
}

const [progress, setProgress] = useState(0);

const uploadImage = async options => {
    const { onSuccess, onError, file, onProgress } = options;
    console.log(file);

    const fmData = new FormData();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
            "content-type": "multipart/form-data"
        },
        onUploadProgress: event => {
            const percent = Math.floor((event.loaded / event.total) * 100);
            setProgress(percent);
            if (percent === 100) {
                setTimeout(() => setProgress(0), 1000);
            }
            onProgress({ percent: (event.loaded / event.total) * 100 });
        }
    };
    fmData.append("files", file);
    try {
        const res = await axios.post(
            CONSTANTS.DEFAULT_URL + "upload",
            fmData,
            config
        );

        onSuccess("Ok");
        console.log("server res: ", res.data[0].id);
	
  
        setUploadId(res.data[0].id);
        setPrescriptionText(t('Plus Prescription Which Will Be Calculate From The Pharmacy'));

    } catch (err) {
        console.log("Eroor: ", err);
        const error = new Error("Some error");
        onError({ err });
    }
};
const get_patient_whatsnumber = (data)=>{
	if(data.patient !== null){
        return (data.patient.profile.whatsappNumber);

	}
}

const handleChange = (info) => {
    if (info.file.status === 'uploading') {
        setLoadings(true);
        return;
    }
    if (info.file.status === 'done') {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, (url) => {
            setLoadings(false);
        });
    }
};

const convertToEgyTime = (datatime) => {
	const timeSlotDatetime = new Date(datatime).getTime();
	const newTimeSlot = new Date(timeSlotDatetime + 2 * 60 * 60 * 1000);
	newTimeSlot.setHours(newTimeSlot.getHours() - 2);

	return newTimeSlot.toLocaleTimeString();
  };
  const ConvertToArabicNumbers = (num) => {
	if(i18n.language === 'ar'){
		const arabicNumbers = '\u0660\u0661\u0662\u0663\u0664\u0665\u0666\u0667\u0668\u0669';
		return new String(num).replace(/[0123456789]/g, (d)=>{return arabicNumbers[d]}).replace("PM","مساءا").replace("AM","صباحا");
	   
	}
	return num;
}
const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};

const uploadButton = (
    <div>
        {loading ? <LoadingOutlined /> : <div class="upload-img">
            <div class="change-photo-btn">
                <span><i class="fa fa-upload"></i>{t('Upload Prescription')}</span>
                <input type="file" class="upload" />
            </div>
        </div>}
      
    </div>
);

const update_state = async (order_id ,status)=>{
	setLoading(true);

	const headers = {
		'Content-Type': 'application/json',
		'Authorization': 'Bearer '+token
	  }
await	axios.put(CONSTANTS.DEFAULT_URL+'polyclinic/updateAppointMentStatus/'+order_id , {
		"data": {
			"status":status
		}
	},{headers : headers} )
.then(function (response) {
	message.success('تم التحديث');
	setTimeout(() => {
			window.location.reload();

	}, 1000);

	// setLoading(false);
}).catch((error)=>{
	message.error(error.response.data.error.message);
	// setLoading(false);


});
}
  return (
<>{load ? (<>
	<div class="col-md-7 col-lg-8 col-xl-9">
		
		<div class="row">
			<div class="col-lg-12">
				<div class="card dash-cards">
		
					<div class="card-body">
						<div class="table-responsive">
						<table class="table table-hover table-center mb-0"   ref={tableRef}> 
												<thead>
													<tr>
														<th>{t('Patient')}</th>
														<th>{t('Order No.')}</th>

														<th>{t('Booking Date')}</th>
														<th>{t('Total')}</th>
														<th>{t('Order Data')}</th>
														<th>{t('Time')}</th>

														<th>{t('Payment Status')}</th>

														<th>{t('Status')}</th>
														<th></th>
													</tr>
												</thead>
												<tbody>
				{appointmentsLoad  && appointment != null ? (

<tr>
<td>

  <h2 class="table-avatar">
    {/* <a href="#" class="avatar avatar-sm me-2">
      <img class="avatar-img rounded-circle" src={get_patient_image(appointment)} alt={get_patient_name(appointment)} />
    </a> */}
    <a href="#">{get_patient_name(appointment)} </a>
  </h2>
</td>
 <td>#{appointment.id}</td>

<td>{ConvertToArabicNumbers(get_booking_date(appointment))}</td>
<td>{ConvertToArabicNumbers(get_fees(appointment))} {t('pound')}</td>
<td>{get_order_data(appointment)}</td>
<td>{ConvertToArabicNumbers(convertToEgyTime(get_timeSlot(appointment)))}</td>

{/* <td></td> */}
<td>{t(get_payment(appointment))}</td>

<td>{t(get_status(appointment))}</td>
<td class="text-end">
  <div class="table-action">
  
{get_status(appointment) !== 'completed' &&  get_status(appointment) !== 'cancelled'	?	(	<a href="#"  onClick={()=>{update_state(params.id,'cancelled')}} class="btn btn-sm bg-danger-light">
     {t('Cancel')}
    </a>) : (<></>) }
  </div>
</td> 
</tr>
  ) : (
	<>
	<Skeleton  avatar
paragraph={{
rows: 4,
}} active />
   </>
  )}
					
									
					</tbody>
											</table>
																<Divider />
																
					<div class="card">
						<div class="card-body pt-0">
						
				
							{/* {timeslot > 0 ? ( */}
							{/* <>	 */}
						

									{get_status(appointment) !== 'completed' ? (<>

							<nav class="user-tabs mb-4">
								
								<ul class="nav nav-tabs nav-tabs-bottom nav-justified">
								{appointment.doctor_service.working_days.length > 0 ?
																(appointment.doctor_service.working_days.map((day , key) => {
																	return (
																		<li class="nav-item day" >
										<a className={`nav-link ${key === 0 ? "active" : ""}`} href={"#doc_"+day.id} data-bs-toggle="tab">{t(day.day)} <br/><>{day.timeslots[0]
                                        ? day.timeslots[0].start.split(
                                            "T"
                                          )[0]
                                        : null}</> </a>
									</li>
																
																	)
																})
																) : (<>{t('Loading')}</>)
															}
									
								</ul>
							</nav>
						
							<div class="tab-content pt-0">
							{appointment.doctor_service.working_days.length > 0 ?
																(appointment.doctor_service.working_days.map((day ,key) => {
																	return (
								<div role="tabpanel" id={"doc_"+day.id}  className={`tab-pane ${key === 0 ? " show active" : ""}`}>
									<div class="row">
									
									<div class="schedule-cont">
											<div class="row">
												<div class="col-md-12">

													<div class="time-slot">
														<ul class="clearfix">
															

																		
																				{day.timeslots.length > 0 ?
																(day.timeslots.map((timeslot) => {
																	return (
																		<>
																		
																		{timeslot.status === "available" ? (<li className='slot_m'>
																			
																			<a className="timing"     onClick={(e) => {
                                              onTimeSlotChange(e, timeslot);
                                            }}>
																				<span>{ConvertToArabicNumbers(convertToEgyTime(timeslot.start))} </span>
																			</a>   
																						 </li>) :(<li  className="text-danger" >
																			
																			<a class="timing"  >
																				<span className='text-danger'>{t(timeslot.status)}</span>
																			</a>   
																						 </li>)}
																		
																						 </>)
																					})
																					) : (<>{t('Not Avaliable')}</>)
																				}       
																				                                             
																	
																
															

														</ul>
													</div>


												</div>
											</div>
										</div>
									</div>
								</div> 
		)
	})
	) : (<>Loading Days</>)
}
								
							</div>
									
									{get_status(appointment) !== 'cancelled' ? (<>
										<div class="submit-section proceed-btn text-end">
									<a href='javascript:void(0)' class="btn btn-primary submit-btn" onClick={()=>{updateTimeSlot()}} >{'تغيير الموعد'} </a>

									</div>
									{get_status(appointment) !== 'inProgress' &&  get_status(appointment) !== 'cancelled' ? (<>

										<div class="submit-section proceed-btn text-end">
									<a href='javascript:void(0)' class="btn btn-primary submit-btn" onClick={()=>{update_state(params.id,'inProgress')}}  >{'بدا الاستشاره'} </a>

									</div>
									</>):(<></>)}

									{get_status(appointment) === 'inProgress' &&  get_status(appointment) !== 'not_attended'  ? (<>

									<div class="submit-section proceed-btn text-end">
									<a href='javascript:void(0)' class="btn btn-primary submit-btn" onClick={()=>{update_state(params.id,'completed')}}  >{'اكتمال الاستشاره'} </a>

									</div>



									<div class="submit-section proceed-btn text-end">
									<a href='javascript:void(0)' class="btn btn-info submit-btn" onClick={()=>{update_state(params.id,'not_attended')}}  >{'لم يحضر المريض'} </a>

									</div>
									
									
									</>):(<></>)}
									</>):(<></>)}

									
									</>):(<></>)}
										
									{/* </>) : (<></>)} */}
									{get_status(appointment) === 'completed' ? (<>
									
										<div class="col-md-5 offset-1">

<Upload
										accept="image/*"
										customRequest={uploadImage}
										onChange={handleChange}
										listType="picture-card"
										className="image-upload-grid"
										defaultFileList={[...fileList]}

									>
										{
											uploadButton
										}
									</Upload>
									</div>
									<div class="row">
                                      <div class="col-md-12">

									  <div class="submit-section proceed-btn text-end">
									<a href='javascript:void(0)' class="btn btn-primary submit-btn" onClick={()=>{AddPharmacyOrder("pharmacy")}} >{t('Order From Pharmacy')} </a>

									</div>
									  </div>
									  <div class="col-md-12">
									  <div class="filter-widget col-md-6">
                                                        <h4>{t('selectSupplier')}</h4>
                                                        <div className='filter-widget'>

                                                            {dataLoaded ? (
                                                                <Select
                                                                    showSearch
                                                                    placeholder={t('selectSupplier')} 
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) =>
                                                                        option.value.toLowerCase().includes(input.toLowerCase())
                                                                    }
                                                                    onChange={(e,key) => setLab(key.value)}
                                                                    style={{ minWidth: 200 }}

                                                                >
																																		

                                                                    {labs.map((labD, i) => {
                                                                        return (
                                                                            <Option value={labD.id}  key={i}>

                                                                                <>{labD.attributes.name}</>

                                                                            </Option>
                                                                        );
                                                                    })}                  </Select>



                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>
                                                    </div>

<div class="submit-section proceed-btn text-end">
<a href='javascript:void(0)' class="btn btn-primary submit-btn" onClick={()=>{AddPharmacyOrder("lab")}} >{t('Order From Lab')} </a>

</div>
</div>
<div class="col-md-12">
<div class="filter-widget col-md-6">
                                                        <h4>{t('selectSupplier')}</h4>
                                                        <div className='filter-widget'>

                                                            {dataLoaded ? (
                                                                <Select
                                                                    showSearch
                                                                    placeholder={t('selectSupplier')}
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) =>
                                                                        option.value.toLowerCase().includes(input.toLowerCase())
                                                                    }
                                                                    onChange={(e,key) => setScan(key.value)}
                                                                    style={{ minWidth: 200 }}

                                                                >
                                                                    {scans.map((labD, i) => {
                                                                        return (
                                                                            <Option value={labD.id}  key={i}>

                                                                                <>{labD.attributes.name}</>

                                                                            </Option>
                                                                        );
                                                                    })}                  </Select>



                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>
                                                    </div>
<div class="submit-section proceed-btn text-end">
<a href='javascript:void(0)' class="btn btn-primary submit-btn" onClick={()=>{AddPharmacyOrder("scan")}} >{t('Order From Scan')} </a>

</div>
</div>

									</div>
									
									
									</>):(<></>)}
										

									
						
						</div>
					</div>
						</div>
					</div>
				</div>
			</div>
		
		</div>
	
	</div>
	</>):(<div class="col-md-7 col-lg-8 col-xl-9"><Skeleton /></div>)}	
	</>
  )
}
