import React, { useEffect, useState } from "react"
import {Link, useNavigate} from 'react-router-dom'

import { useTranslation } from "react-i18next";
import "./Header.css";
import axios from "axios";
import i18n from "../../../i18n";
import * as CONSTANTS from '../../../CONSTANTS';
import useToken from "../../useToken";
import Spin  from 'antd/es/spin';
import { Button, Dropdown, Nav } from "react-bootstrap";
import { Modal } from "antd";

import { useLocation } from 'react-router-dom';

function Header({setLanguage ,setUserData , userData ,phoneP, phoneD = '' , email=''}){
const [userName, setUserName] = useState();
const {t} = useTranslation('home');
const [isLoaded, setDataLoaded] = useState(false);
const [profilePic, setProfilePic] = useState(null);
const navigate = useNavigate();
const [type, setType] = useState('normal');
const { token, setToken } = useToken();
const [logged, setLogged] = useState(false);

const location = useLocation();
console.log(location.pathname);
const handleLangChange = lang => {
	i18n.changeLanguage(lang.key)

    setLanguage(lang.key);
	window.location = '/'+lang.key;

  };
const items = [
	{
	  label: 'عربي',
	  key: 'ar',
	  icon: <></> ,
	},
	{
	  label: 'English',
	  key: 'en',
	  icon: <></> ,
	},
	
  ];
  const menuProps = {
	items,
	onClick: handleLangChange,
  };
 
const handelLogout = ()=>{
	setToken(null);
	window.location = '/ar/login';
}


		useEffect(() => {
			setDataLoaded(true);

			const user = JSON.parse(userData);
			console.log('user Data',user);

			if(user != null && user.polyclinic != null){
				setUserName(user.polyclinic.name);
				setLogged(true);

			

			// setUserName(user.polyclinic.name);
			setType(user.type);
			if(user.userInfo){
				setProfilePic(user.userInfo.profiles[0]?.profilePicture?.url);
				

			}
						setDataLoaded(false);
		 
	}else{
		setDataLoaded(false);

	}

	}, []);
  return (
    <>
	
	<Spin spinning={isLoaded}>

	<header class="header">
	<nav className="navbar navbar-expand-lg navbar-light bg-light">
				<div className="container">

					<div class="navbar-header">
						<a id="mobile_btn" href="javascript:void(0);">
							<span class="bar-icon bar-icon-one">
								<span></span>
								<span></span>
								<span></span>
							</span>
						</a>
						<Link to={"/"} class="navbar-brand logo">
							<img src="/assets/img/Logo&Slogan (Colored).png" class="img-fluid" alt="Logo" />
						</Link>
					</div>
					<div class="main-menu-wrapper">
						<div class="menu-header">
							<Link to={"/"} class="menu-logo">
								<img src="/assets/img/Logo&Slogan (Colored).png" class="img-fluid" alt="Logo" />
							</Link>
							<a id="menu_close" class="menu-close" href="javascript:void(0);">
								<i class="fas fa-times"></i>
							</a>
						</div>
						<ul class="main-nav">
						
							
					
					

							
						</ul>		 
					</div>		 
					<ul class="nav header-navbar-rht">
		
					{/* <li class="nav-item">

					<Nav.Item href="#link">	 <ul className="nav header-navbar-rht">
		  <li className="nav-item view-cart-header me-3">
		  <Dropdown menu={menuProps}>
      <Button>
          عربي
      </Button>
    </Dropdown>
	</li>
	</ul></Nav.Item>
		</li> */}
		
						<li class="nav-item">
			
		 
		  			{logged === true && (
						<>
							<li class="nav-item dropdown has-arrow logged-item">
								<a href="#" class="dropdown-toggle nav-link" data-bs-toggle="dropdown">
									<span class="user-img">
									<img width="31" class="rounded-circle" src={(profilePic != null ? (profilePic) : ('/assets/img/patients/patient.jpg'))}  alt={userName} />

									</span>
								</a>
								<div class="dropdown-menu dropdown-menu-end">
									<div class="user-header">
										<div class="avatar avatar-sm">
										<img  class="avatar-img rounded-circle" src={(profilePic != null ? (profilePic) : ('/assets/img/patients/patient.jpg'))}  alt={userName} />

										</div>
										<div class="user-text">
											<h6>{userName}</h6>
											<p class="text-muted mb-0">{type}</p>
										</div>
									</div>
									
							

									{/* <Link class="dropdown-item" to={'/profile'}>{t('profile')}</Link> */}
								
								
									<a class="dropdown-item" href="#" onClick={handelLogout}>{t('logout')}</a>
								</div>
							</li>
						
							</>
							

          )}

						</li>
						<li>
			
						</li>
					
					</ul>
					</div>
				</nav>
				
			</header>
			</Spin>
    </>
  )
}
export default Header