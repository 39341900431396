import { Alert } from "antd";
import React from "react";






class ErrorBoundary extends React.Component {
  state = {
    errorMessage: '',
  };

  static getDerivedStateFromError(error) {
    return { errorMessage: error.toString() };
  }

  componentDidCatch(error, info) {
    this.logErrorToServices(error.toString(), info.componentStack);
  }

  // A fake logging service.
  logErrorToServices = console.log;

  render() {
    if (this.state.errorMessage) {
      return  (<><div>
      <Alert color="blue" size={100} />
      <span>WE CANT CONNECT TO DOXX SERVERS NOW</span>
      <p>{this.state.errorMessage}</p>
    </div></>) ;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;