import { Select, Skeleton,TimePicker } from 'antd'
import React, { Suspense, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import i18n from '../../i18n'
import * as CONSTANTS from '../../CONSTANTS'
import axios from 'axios'
import { Button, message,Modal } from 'antd';
import { useTranslation } from 'react-i18next'
import { DoctorDashboradNavBar } from './DashboradNavBar'
import GoogleMapReact from 'google-map-react';
import { keyboard } from '@testing-library/user-event/dist/keyboard'
import PhoneInput from 'react-phone-input-2';

function validatePrice(price) {
  // Check if the price is a number.
  if (!isNaN(price)) {
    // Check if the price is greater than zero.
    if (price > 0) {
      // The price is valid.
      return true;
    } else {
      // The price is invalid.
      return false;
    }
  } else {
    // The price is not a number.
    return false;
  }
}
function validateEgyptianPhoneNumber(phoneNumber) {
  phoneNumber = "+"+phoneNumber
  // Check if the phone number is empty.
  if (phoneNumber === '') {
    return false;
  }

  // Check if the phone number starts with the country code 0020 or +20.
  if (!/^0020|\+20/.test(phoneNumber)) {
    return false;
  }
  // Check if the phone number has 13 digits.
  if (phoneNumber.length !== 13) {
    return false;
  }

  // Check if the phone number is in the correct format.
  const regex = /^(00201|\+201|01)[0-2,5]{1}[0-9]{8}$/;
  if (!regex.test(phoneNumber)) {
    return false;
  }

  // The phone number is valid.
  return true;
}
function sleep(miliseconds) {
  var currentTime = new Date().getTime();

  while (currentTime + miliseconds >= new Date().getTime()) {
  }
}
function makeid(length) {
    var result           = [];
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result.push(characters.charAt(Math.floor(Math.random() * 
 charactersLength)));
   }
   return result.join('9');
}
async function registerClinic( token ,name , phone , fees , daysInAdvance ,consultationDuration  ,polyclinicId  , doctorName, username,doctorLastName,specialityData , address_id ,specialistName,polclinicName , doctorPhone,followupFee,doctorBio,insurencesData) {

 let doctorId = await localStorage.getItem("doctorId");

 let doctorJwt = await localStorage.getItem("doctorJwt");



 
if(doctorName.trim().length === 0){
  message.error("الأسم الأول مطلوب");
  return false;
}
if(doctorLastName.trim().length === 0){
  message.error("الأسم الأخير مطلوب");
  return false;
}

if(consultationDuration === '' || consultationDuration === null || consultationDuration === undefined){
  message.error(" وقت الاستشاره مطلوب");
  return false;
}

consultationDuration = Number(consultationDuration);
if(typeof consultationDuration !== "number"){
  message.error(" وقت الاستشاره مطلوب");
  return false;
}
if(doctorPhone !== null && doctorPhone !== undefined && doctorPhone !== ''){
    var email = null;
if(!validateEgyptianPhoneNumber(doctorPhone)){
  message.error("ادخل رقم هاتف صحيح");
  return false;
}else{
  username = "+"+doctorPhone;
}

}
if(username === null || username === undefined || username === ''){
  email = makeid(8)+"@doxx.care"
  username = email;
}




if (validatePrice(fees) === false) {
  message.error(" السعر مطلوب");
  return false;

}
if(specialityData.length === 0){
  message.error("اختر التخصص اولا");
  return false;
}

name = polclinicName+" ( "+specialistName+" )";


message.loading();
let response = await  fetch(CONSTANTS.DEFAULT_URL+'users/me', {
  method: 'GET',
  headers: {
    'Content-Type': 'application/json' ,
    'Authorization': 'Bearer '+doctorJwt

  },
})
let json = await response.json();
if(response.status === 200 ){
  await localStorage.setItem("doctorJwt",doctorJwt);
  await localStorage.setItem("doctorId",json.profiles[0].doctor.id);
  doctorId =json.profiles[0].doctor.id ;
  doctorJwt = doctorJwt;
}else{
  localStorage.removeItem("doctorId");
  localStorage.removeItem("doctorJwt");
  doctorId = null;
  doctorJwt = null;
}



    try {
      console.log("tryyyyyyyyy");

        let password = makeid(8);
        let firstname = doctorName;
        var data = {};
        if(email === null){
          data = {
            username:username,
          password:password,
          lastname:doctorLastName,
          firstname:firstname,
          lastname_ar:doctorLastName,
          firstname_ar:firstname,
          about:doctorBio,
          type:"doctor",
          polyclinicId:polyclinicId,
          specialities:specialityData,
          insurances :insurencesData,
          applicationLanguage:i18n.language,
        };
        }else{
          data = {
            username:username,
            email:email,
          password:password,
          lastname:doctorLastName,
          firstname:firstname,
          lastname_ar:doctorLastName,
          about:doctorBio,

          firstname_ar:firstname,
          type:"doctor",
          polyclinicId:polyclinicId,
          specialities:specialityData,
          insurances :insurencesData,
          applicationLanguage:i18n.language,
        }; 
        }
        console.log(data);

  if(doctorJwt === null && doctorId === null){

    let response = await  fetch(CONSTANTS.DEFAULT_URL+'auth/local/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+token

      },
      body: JSON.stringify(data)
    })
    let userData = await response.json();

    if(userData.error){
      message.error(userData.error.message);

    //  if(userData.error.details.errors[0].isArray == true){
    //   message.error(userData.error.details.errors[0].message);

   
      return false;
    }else if(userData.message){
      message.error(userData.message[0].messages[0].message);
      return false;

    }
    
    doctorJwt = userData.jwt;
    localStorage.setItem("doctorJwt",doctorJwt);
    sleep(2);
     await  fetch(CONSTANTS.DEFAULT_URL+'users/me', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json' ,
        'Authorization': 'Bearer '+doctorJwt
      },
    }).then(async (response) => {

      if (!response.ok) { message.error("لم يتم اضافة طبيب"); }
  return response.json()
      
      
      
      //we only get here if there is no error
    }).then(async(currentDoctor)=>{
      // let currentDoctor =response.json();
      console.log('4444',currentDoctor);
      doctorId= await currentDoctor.profiles[0].doctor.id;
      await localStorage.setItem("doctorId",doctorId);
      
      if(doctorId === null && doctorJwt === null){
 
        // window.location = "/"+i18n.language+"/login"
        message.error(" خطا في اضافة طبيب");
        return false;
  }

if(doctorId > 0 && doctorJwt !== null && doctorJwt.trim() !== ""){
try {
let response = await  fetch(CONSTANTS.DEFAULT_URL+'doctor-services?populate=working_days', {
method: 'POST',
headers: {
  'accept': 'application/json',

  'Content-Type': 'application/json',

  'Authorization': 'Bearer '+doctorJwt

},
body: JSON.stringify({
  "data": {
      "fee": fees,


      "doctor":doctorId,
      "daysInAdvance":parseInt(daysInAdvance),
      "consultationDuration" : parseInt(consultationDuration),
      "serviceType": "clinic",
       "isRange" :false,
      "clinic":{
        "address": {
          "street":address_id.street,
          "followupFee":followupFee,
          "defaultAddress": true,
          "cities": [address_id.cities[0].id],
          "tag":address_id.tag,
          "zipCode": address_id.zipCode
      },
          "name": name,
          "polyclinic": polyclinicId,

          "phoneNumber": phone,

      "doctors":[doctorId],
      // "address":address_id,
  },
      "working_days": [
          // {
          //     "day": "sunday",
          //     "ranges": [
          //         {
          //             "start": "13:00:00",
          //             "end": "20:00:00",
          //             "capacity": 10
          //         }
          //     ]
          // }
      ]
  }
}
)
})
let json = await response.json();
message.success("تمت الاضافة بنجاح");
await localStorage.removeItem("doctorId");
await localStorage.removeItem("doctorJwt");

setTimeout(()=>{

                window.location = "/ar/clinics";

},1000)
  return { success: true, data: json };



} catch (error) {
alert(error);
message.error(error);

return { success: false };
}

}
 
  
    }).catch(()=>{
      message.error("حدث خطا اثناء الاضافة");
    })





  }else{
    try {
      let response = await  fetch(CONSTANTS.DEFAULT_URL+'doctor-services?populate=working_days', {
      method: 'POST',
      headers: {
        'accept': 'application/json',
      
        'Content-Type': 'application/json',
      
        'Authorization': 'Bearer '+doctorJwt
      
      },
      body: JSON.stringify({
        "data": {
            "fee": fees,
      
      
            "doctor":doctorId,
            "daysInAdvance":parseInt(daysInAdvance),
            "consultationDuration" : parseInt(consultationDuration),
            "serviceType": "clinic",
             "isRange" :false,
            "clinic":{
              "address": {
                "street":address_id.street,
                "followupFee":followupFee,
                "defaultAddress": true,
                "cities": [address_id.cities[0].id],
                "tag":address_id.tag,
                "zipCode": address_id.zipCode
            },
                "name": name,
                "polyclinic": polyclinicId,
      
                "phoneNumber": phone,
      
            "doctors":[doctorId],
            // "address":address_id,
        },
            "working_days": [
                // {
                //     "day": "sunday",
                //     "ranges": [
                //         {
                //             "start": "13:00:00",
                //             "end": "20:00:00",
                //             "capacity": 10
                //         }
                //     ]
                // }
            ]
        }
      }
      )
      })
      let json = await response.json();
      message.success("تمت الاضافة بنجاح");
      await localStorage.removeItem("doctorId");
      await localStorage.removeItem("doctorJwt");
      
      setTimeout(()=>{
      
                      window.location = "/ar/clinics";
      
      },1000)
        return { success: true, data: json };
      
      
      
      } catch (error) {
      alert(error);
      message.error(error);
      
      return { success: false };
      }

  }


 
    
    } catch (error) {
      console.log("error",error);
        return { success: false };
      }    


}
export default function CreateClinic  ({token}){

    const [value, setValue] = useState(null);
 
    const [doctorName, setDoctorName] = useState('');
    const [doctorPhone, setDoctorPhone] = useState('');
    const [doctorLastName, setDoctorLastName] = useState('');
    const [specialistName, setSpecialtiesName] = useState('');
    const [insurenceName, setInsurencesName] = useState('');

    const {t} = useTranslation('home');


  
    

//http://3.70.182.78:1337/api/clinics?filters[$and][0][doctors][licenseNumber][$eq]=16881
const [load , setLoading] = useState(true);

const [userData , setData] = useState();

const [clinicName , setClinicName] = useState(null);
const [clinicPhone , setClinicPhone] = useState(null);
const [doctor , setDoctor] = useState(null);

const [followupFee , setFollowupFee] = useState(null);


const [cliniccity , setCliniccity] = useState(null);
const [cliniczipCode , setCliniczipCode] = useState(null);
const [fee , setClinicFees] = useState(null);
const [daysInAdvance , setdaysInAdvance] = useState(null);
const [consultationDuration , setconsultationDuration] = useState(null);

const [specialities , setSpecialties] = useState([]);
const [specialityData , setSpecialtyData] = useState([]);
const [doctorBio , setDoctorBio] = useState("");
const [insurences , setInsurences] = useState([]);
const [insurencesData , setInsurencesData] = useState([]);


const [key , setKey] = useState(0);
const [polyclinicId , setPolyclinicId] = useState(null);

const [clinicarea , setclinicarea] = useState(null);

const { Option } = Select;
const [clinicLoad , setClinicsLoad] = useState(null);
const [addressId , setAddressId] = useState(null);
const [polyclinicName , setPolyclinicName] = useState(null);




const navigate = useNavigate();


useEffect(() => {

setLoading(true);
    if(token != null ){
        setLoading(true);
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+token
          }
        axios.get(CONSTANTS.DEFAULT_URL+'users/me' , {
            headers: headers
          })
.then(function (response) {
    setPolyclinicId(response.data.polyclinic.id);
    setPolyclinicName(response.data.polyclinic.name);
    setClinicPhone(response.data.mobileNumber);
    setAddressId(response.data.polyclinic.address);

  setLoading(true);

       if(response.status === 200){
        setData(response.data);

        setLoading(false);

       }else{
        navigate('/'+i18n.language+'/login/doctor');
       }
    

})
.catch(function (error) {
    navigate('/'+i18n.language+'/login');

setLoading(true);

if(error.response.status === 403){
    navigate('/'+i18n.language+'/login');


}
  console.log(error);
})
// .finally(function () {
//     setLoading(false);
// });
}else{
navigate('/'+i18n.language+'/login');

setLoading(true);

}
const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer '+token
  }



axios.get(CONSTANTS.DEFAULT_URL+'specialities?locale=ar&pagination[limit]=100&pagination[start]=0' , {
  headers: headers
})
.then(function (response) {

  setSpecialties(response.data.data);
})
.catch(function (error) {
// handle error
console.log(error);

});

axios.get(CONSTANTS.DEFAULT_URL+'insurances?locale=ar&pagination[limit]=100&pagination[start]=0' , {
  headers: headers
})
.then(function (response) {

  setInsurences(response.data.data);
})
.catch(function (error) {
// handle error
console.log(error);

});

}, [key]);



  return (
    <Suspense>
        {load === false ? (<>
            <div class="col-md-7 col-lg-8 col-xl-9">
                            <div class="card">
                                <div class="card-body">
                                <form>
                                                <div class="row form-row">
                                                <div class="col-12 col-md-6">
                                                        <div class="form-group">
                                                            <label>{t('Doctor First Name')}</label>
                                                            <input type="text" class="form-control" onChange={(e)=>{setDoctorName(e.target.value)}} value={doctorName} />
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6">
                                                        <div class="form-group">
                                                            <label>{t('Doctor Last Name')}</label>
                                                            <input type="text" class="form-control" onChange={(e)=>{setDoctorLastName(e.target.value)}} value={doctorLastName} />
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6">
                                                    <div class="form-group form-focus" style={{"direction":"ltr"}}>
                                                            <label>{t('Doctor Phone')}</label>
                                                            
                                                            <PhoneInput
                                required
                                
  country={"eg"}
  inputClass={"form-control floating"}
  inputStyle={{width:"100%",paddingBottom:"20px"}}
  preferredCountries={['eg']}
  value={doctorPhone}
  onChange={(e) => setDoctorPhone(e)}
/>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6">
                                                        <div class="form-group">
                                                            <label>{t('Bio')}</label>
                                                            <input type="text" class="form-control" onChange={(e)=>{setDoctorBio(e.target.value)}} value={doctorBio} />
                                                        </div>
                                                    </div>
                                                    {/* <div class="col-12 col-md-6">
                                                        <div class="form-group">
                                                            <label>{t('Clinic Phone')}</label>
                                                            <input type="text" onChange={(e)=>{setClinicPhone(e.target.value)}} class="form-control" value={clinicPhone}  />
                                                        </div>
                                                    </div> */}
                                                    <div class="col-12 col-md-6">
                                                        <div class="form-group">
                                                            <label>{t('Clinic Consultation time for every patient')}</label>
                                                            <input type="number" required onChange={(e)=>{setconsultationDuration(e.target.value)}} value={consultationDuration} class="form-control"  />
                                                        </div>
                                                    </div>

                                                    <div class="col-12 col-md-6">
                                                        <div class="form-group">
                                                            <label>{t('FollowupFee')}</label>
                                                            <input type="number" required onChange={(e)=>{setFollowupFee(e.target.value)}} value={followupFee} class="form-control"  />
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6">
                                                        <div class="form-group">
                                                            <label>{t('Fees')}</label>
                                                            <input type="number" onChange={(e)=>{setClinicFees(e.target.value)}} class="form-control" value={fee}  />
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6">
                                                        <div class="form-group">
                                                            <label>{t('Number of days available for pre-booking')} </label>
                                                            <input type="number" onChange={(e)=>{setdaysInAdvance(e.target.value)}} class="form-control" value={daysInAdvance}  />
                                                        </div>
                                                    </div>
                                                                                                      <div class="col-12 col-md-6">
                                                        <div class="form-group">
                                                            <label>التخصص </label>
                                               
                    <Select
                    size='large'
 
                      showSearch
                      placeholder={'اختر التخصص'}
                      optionFilterProp="children"
                      mode='tags'
                      filterOption={(input, option) =>
                        option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 ||
                        option.props.label.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                    }
                      onChange={(e ,k) =>{setSpecialtyData(e)}}
                      className="col-md-12 "
                    >
                      {specialities.map((c, i) => {
                        return (
                          <Option value={c.id} key={c.attributes.name} label={c.attributes.name}>
                            <>{c.attributes.name}</>
                          </Option>
                        );
                      })}                  </Select></div>
                                              
                                                 

</div>
<div class="col-12 col-md-6">
                                                        <div class="form-group">
                                                            <label>شركات التامين </label>
                                               
                    <Select
                    size='large'
 
                      showSearch
                      placeholder={'اختر الشركه'}
                      optionFilterProp="children"
                      mode='tags'

                      filterOption={(input, option) =>
                        option.props.value.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0 ||
                        option.props.label.toString().toLowerCase().indexOf(input.toString().toLowerCase()) >= 0
                    }
                      // value={insurenceName}
                      onChange={(e ,k) =>{setInsurencesData(e)}}
                      className="col-md-12 "
                    >
                      {insurences.map((c, i) => {
                        return (
                          <Option value={c.id} key={c.attributes.name} label={c.attributes.name}>
                            <>{c.attributes.name}</>
                          </Option>
                        );
                      })}                  </Select></div>
                                              
                                                 

</div>
               

{/* <div style={{ height: '400px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <mapMarker
          lat={59.955413}
          lng={30.337844}
          text="My Marker"
        />
      </GoogleMapReact>
    </div> */}

                                                </div>
                     

                                                <div class="submit-section">
                                                    <button type="button" onClick={()=>{registerClinic(token,clinicName , clinicPhone ,  fee,daysInAdvance ,consultationDuration  ,polyclinicId , doctorName,doctorPhone,doctorLastName,specialityData,addressId , specialistName,polyclinicName,doctorPhone , followupFee,doctorBio,insurencesData )}} class="btn btn-primary submit-btn">{t('Save Changes')}</button>
                                                </div>
                                            </form>
                                </div>
                            </div>
                        </div></>) : (<div class="col-md-7 col-lg-8 col-xl-9"><Skeleton /></div>)}

    </Suspense>

  )
}
