import { message, Row, Space } from 'antd';
import Skeleton  from 'antd/es/skeleton';
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { check_auth } from '../../helper'
import i18n from '../../i18n';
import { DashboradNavBar } from './DashboradNavBar'
import * as CONSTANTS from '../../CONSTANTS'
import axios from 'axios';
import { Select } from 'antd'

export const PolyclinicAppointments = ({token }) => {
	const [load,setLoading] = useState(false);
	const [upComingVisit,setUpComingVisit] = useState(0);
	const {t} = useTranslation('home');
		const [cancelledOrdersNum,setCancelledOrdersNum] = useState(0);
	const [totalRev,setTotalRev] = useState(0);
	const [totalRevToday,setTotalRevToday] = useState(0);
    const [appointmentsLoad , setAppointmentsLoading] = useState(false);
    const [key , setKey] = useState(0);
    const [name , setName] = useState('');
    const [date , setDate] = useState('');
    const [specialty , setSpecialty] = useState('');

    const [userData , setData] = useState();
	const [profilePic, setProfilePic] = useState(null);
    const [appointments , setAppointmentsData] = useState([]);
	const [messageApi, contextHolder] = message.useMessage();
    const [patientName , setPatientName] = useState('');
    const [specialistName, setSpecialtiesName] = useState('');

	const [specialities , setSpecialties] = useState([]);
	const [specialityData , setSpecialtyData] = useState("");
	
	const { Option } = Select;

    const tableRef = useRef(null);

	const getAppointments = async (name = null)=>{
		var query = '';
		if(name){
          query = '&searchWord='+name;
		}
		if(date){
			query = '&date='+date;
		  }
		  if(specialty){
			query = '&speciality='+specialty;
		  }

		  
		try {
	
			let response = await  fetch(CONSTANTS.DEFAULT_URL+'polyclinic/getOrders?s=true'+query, {
			  method: 'GET',
			  headers: {
				'Content-Type': 'application/json' ,
				'Authorization': 'Bearer '+token
	
			  },
			})
			let json = await response.json();
			console.log(json);
			if(response.status !== 200){
				//   window.location = "/"+i18n.language+""
			}
		
				return { success: true, data: json };
		
			
		
		} catch (error) {
			return { success: false };
		  }
	
	 }	
 const getUser = async ()=>{
	return await check_auth(token);
 }	
 const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer '+token
  }

 useEffect(() => {
	

		getAppointments().then((ap)=>{
			setAppointmentsData(ap.data.result);
	
			setAppointmentsLoading(true);
	
			setLoading(true)
	
	
	

	});
	axios.get(CONSTANTS.DEFAULT_URL+'specialities?locale=ar' , {
		headers: headers
	  })
	  .then(function (response) {
	  
		setSpecialties(response.data.data);
	  })
	  .catch(function (error) {
	  // handle error
	  console.log(error);
	  
	  });
 }, [key])
 const get_patient_image = (data)=>{
	if(data.patient.data!== null){
		return data.patient.profile.maritalStatus;

	}
}

	const get_status = (data)=>{

		return data.appointment_status;
	
		
	}
		const get_timeSlot = (data)=>{

		if(data.start_date !== null){
		return data.start_date;
	
		}
	}
	const get_fees = (data)=>{

		if(data.total_price !== null){
		return data.total_price;
	
		}
	}
	const get_payment = (data)=>{

		if(data.payment_status !== null){
		return data.payment_status;
	
		}
	}
	const get_booking_date = (data)=>{

		if(data.created_at !== null){
			var date = new Date(data.created_at);

		return date.toLocaleDateString('ar-EG-u-nu-latn',{weekday: 'long', year: 'numeric', month: 'short', day: 'numeric'});

		}
	}
	const get_order_data = (data)=>{
		var d = [];

	//   if(data.appointment.doctor_service !== null){
	// 	  var type =  data.appointment.doctor_service.serviceType;
	// 	  var consultationDuration = data.appointment.doctor_service.consultationDuration
	//    if(type === 'telehealth'){
	// 	  d.push(t('TeleHealth'));
	// 	  d.push(" ("+consultationDuration+" "+t("Minute")+")");

	//    }else if(type === 'clinic'){
	// 	d.push(t('Clinic'));

	// }else if(type === 'homevisit'){
	// 	d.push(t('Home Visit'));

	//    }
	// 	  }
		  return d;
  }
  const get_patient_name = (data)=>{
	if(data.patient !== null ){
		if(i18n.language === 'ar' && data.patient_firstname_ar !== null && data.patient_firstname_ar !== ''){
			return (data.patient_firstname_ar + ' '+data.patient_lastname_ar);

		}
        return (data.patient_firstname + ' '+data.patient_lastname);

	}
}

const get_doctor_name = (data)=>{
	if(data.doctor !== null ){
		if(i18n.language === 'ar' && data.firstname_ar !== null && data.firstname_ar !== ''){
			return (data.firstname_ar + ' '+data.lastname_ar);

		}
        return (data.firstname + ' '+data.lastname);

	}
}
const get_patient_whatsnumber = (data)=>{
	if(data.patient !== null){
        return (data.patient.profile.whatsappNumber);

	}
}
const search = ()=>{
	getAppointments(name,date,specialty).then((ap)=>{
		setAppointmentsData(ap.data.result);

		setAppointmentsLoading(true);

		setLoading(true)




});
}
const update_state = async (order_id ,status)=>{
	setLoading(true);

	const headers = {
		'Content-Type': 'application/json',
		'Authorization': 'Bearer '+token
	  }
await	axios.put(CONSTANTS.DEFAULT_URL+'polyclinic/updateAppointMentStatus/'+order_id , {
		"data": {
			"status":status
		}
	},{headers : headers} )
.then(function (response) {
	message.success('تم التحديث')
	setKey(Math.random());
	// setLoading(false);
}).catch((error)=>{
	message.error(error.response.data.error.message);
	// setLoading(false);


});
}
const convertToEgyTime = (datatime) => {
	const timeSlotDatetime = new Date(datatime).getTime();
	const newTimeSlot = new Date(timeSlotDatetime + 2 * 60 * 60 * 1000);
	newTimeSlot.setHours(newTimeSlot.getHours() - 2);

	return newTimeSlot.toLocaleTimeString();
  };
  const ConvertToArabicNumbers = (num) => {
	if(i18n.language === 'ar'){
		const arabicNumbers = '\u0660\u0661\u0662\u0663\u0664\u0665\u0666\u0667\u0668\u0669';
		return new String(num).replace(/[0123456789]/g, (d)=>{return arabicNumbers[d]}).replace("PM","مساءا").replace("AM","صباحا");
	   
	}
	return num;
}
  return (
	
<>{load ? (<>

	<div class="col-md-7 col-lg-8 col-xl-9">
	
		<div class="row">
			<div class="col-lg-12">
				<div class="card dash-cards">
					<div class="card-header">
						<div class="today-appointment-title">
							<h5 class="mb-0">{t('Appointments')}</h5>
						</div>
					</div>
					<dic class="row">
						<div class="col-md-3"><input class="form-control "  value={date}  onChange={(e)=>{setDate(e.target.value)}}  type={'date'}placeholder={t('date')} /></div>
						<div class="col-md-3"><input  class="form-control " type={'text'}  value={name}  onChange={(e)=>{setName(e.target.value)}}  placeholder={t('اسم الطبيب')} /></div>
						<div class="col-md-3"><input  class="form-control " type={'text'}  value={patientName}  onChange={(e)=>{setPatientName(e.target.value)}}  placeholder={t('اسم المريض')} /></div>


						<div class="col-md-3">   <Select
                    size='large'

                      showSearch
                      placeholder={'اختر التخصص'}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                      value={specialty}
                      onChange={(e) =>{setSpecialty(e)}}
                      className="col-md-12 "
                    >
                      {specialities.map((c, i) => {
                        return (
                          <Option value={c.id} key={c.attributes.name}>
                            <>{c.attributes.name}</>
                          </Option>
                        );
                      })}                  </Select></div>
						<div class="col-md-3"><button onClick={()=>search()} class="btn btn-primary">{t('بحث')}</button></div>

					</dic>
					<div class="card-body">
						<div class="table-responsive">
						<table class="table table-hover table-center mb-0" ref={tableRef} id="datatable"> 
												<thead>
													<tr>
														<th>{t('Patient')}</th>
														<th>{t('Order No.')}</th>
														<th>{t('Doctor')}</th>

														<th>{t('Booking Date')}</th>
														<th>{t('Total')}</th>
														<th>{t('Order Data')}</th>
														<th>{t('Time')}</th>

														<th>{t('Payment Status')}</th>

														<th>{t('Status')}</th>
														<th></th>
													</tr>
												</thead>
												<tbody>
				{appointmentsLoad ? (
appointments.length > 0 ? (

	appointments.map((appointment, i) => {
	 
	  return (
		appointment != null ? <>	
		
					
													<tr>
														<td>

															<h2 class="table-avatar">
																{/* <a href="#" class="avatar avatar-sm me-2">
																	<img class="avatar-img rounded-circle" src={get_patient_image(appointment)} alt={get_patient_name(appointment)} />
																</a> */}
																<a href="#">{get_patient_name(appointment)} </a>
															</h2>
														</td>
														 <td>#{appointment.orderId}</td>
														 <td>

{get_doctor_name(appointment)}
</td>
														<td>{ConvertToArabicNumbers(get_booking_date(appointment))}</td>
														<td>{ConvertToArabicNumbers(get_fees(appointment))} {t('pound')}</td>
													<td>{get_order_data(appointment)}</td>
													<td>{ConvertToArabicNumbers(convertToEgyTime(get_timeSlot(appointment)))}</td>

													{/* <td></td> */}
														<td>{t(get_payment(appointment))}</td>

														<td>{t(get_status(appointment))}</td>
														<td class="text-end">
															<div class="table-action">
																<Space>
															<Link to={"/"+i18n.language+"/appointment/"+appointment.orderId}  class="btn btn-sm bg-info-light">
																 {t('View')} 
																</Link>
													{get_status(appointment) !== 'completed' && get_status(appointment) !== 'cancelled'	?	(<>	<a href="#" onClick={()=>{update_state(appointment.orderId,'cancelled')}}  class="btn btn-sm bg-danger-light">
																 {t('Cancel')}
																</a>
																{get_status(appointment) !== 'cancelled' && get_status(appointment) !== 'inProgress'	?	(<>
																	<a href="#" onClick={()=>{update_state(appointment.orderId,'inProgress')}}  class="btn btn-sm bg-danger-light">
																 {t('بدا الأستشاره')}
																</a></>
															   ) : (<></>) }
																{get_status(appointment) !== 'cancelled'	?	(<>
																<Link to={"/"+i18n.language+"/appointment/"+appointment.orderId}  class="btn btn-sm bg-danger-light">
																{t('Rescheduled')}
															   </Link></>
															   ) : (<></>) }
{/* <a href="#" onClick={()=>{update_state(appointment.orderId,'scheduled')}}  class="btn btn-sm bg-danger-light">
{t('scheduled')}
</a> */}

</>
																) : (<></>) }
																</Space>
															</div>
														</td> 
													</tr>
							
			
	</>: <></>
				
	   )
	}
	)
	) : (
	  <></>
	)
  ) : (
	<>
	<Skeleton  avatar
paragraph={{
rows: 4,
}} active />
   </>
  )}
					
									
					</tbody>
											</table>
						</div>
					</div>
				</div>
			</div>
		
		</div>
	
	</div>
</>):(<div class="col-md-7 col-lg-8 col-xl-9"><Skeleton /></div>)}	
	</>
  )
}
