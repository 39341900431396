import { Divider, message } from 'antd';
import Skeleton  from 'antd/es/skeleton';
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { check_auth } from '../../helper'
import i18n from '../../i18n';
import { DashboradNavBar } from './DashboradNavBar'
import * as CONSTANTS from '../../CONSTANTS'
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import  LoadingOutlined from '@ant-design/icons/es/icons/LoadingOutlined';
import  Upload  from 'antd/es/upload';
import Select from 'antd/es/select';

let timeout;
let currentValue;
const fetch2 = (value, callback,setData) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    }
    currentValue = value;
    const fake = () => {
  
      axios.get(CONSTANTS.DEFAULT_URL+`profiles?&filters[$or][0][$or][0][firstname][$containsi]=${value}&filters[$or][0][$or][1][lastname][$containsi]=${value}
      &filters[$or][0][$or][2][user][mobileNumber][$containsi]=${value}&filters[$or][0][$or][3][user][email][$containsi]=${value}&filters[$or][1][$and][0][firstname][$containsi]=${value}
      &filters[$or][1][$and][1][lastname][$containsi]=&populate[profilePicture]=*&populate[patient]=*&populate[user][role]=*&sort=[user][createdAt]:desc&filters[user][role]=4`)
      //   .then((response) => response.json())
        .then((d) => {
          if (currentValue === value) {
              console.log(d);
            const  data  = d.data;
            setData(data);
            const data2 = data.data.map((item) => ({
              value: item.attributes.patient?.data?.id,
              text: item.attributes.firstname+' '+ item.attributes.lastname,
            }));
            callback(data2);
          }
        });
    };
    if (value) {
      timeout = setTimeout(fake, 300);
    } else {
      callback([]);
    }
  };
export const OrderForPatient = ({token }) => {

	const [load,setLoading] = useState(false);
	const [upComingVisit,setUpComingVisit] = useState(0);
	const {t} = useTranslation('home');
		const [cancelledOrdersNum,setCancelledOrdersNum] = useState(0);
	const [totalRev,setTotalRev] = useState(0);
	const [totalRevToday,setTotalRevToday] = useState(0);
    const [appointmentsLoad , setAppointmentsLoading] = useState(false);

    const [userData , setData] = useState();
	const [profilePic, setProfilePic] = useState(null);
    const [appointment , setAppointmentData] = useState();
	const [messageApi, contextHolder] = message.useMessage();
    const tableRef = useRef(null);
	const [uploadId, setUploadId] = useState(null);
    const [prescriptionText,setPrescriptionText] = useState('');
	const [timeslot, setTimeSlot] = useState(null);
	const [loading, setLoadings] = useState(false);
    const [patientId, setpatientId] = useState(null);
	const [code, setCode] = useState(null);
    const [redeemedPoints, setRedeemedPoints] = useState(null);
    const [doctorId, setDoctorId] = useState(null);

    const { Option } = Select;

	const AddPharmacyOrder = async (type) => {
		if(uploadId === null || uploadId === undefined || uploadId === ''){
message.error("ارفع الروشته اولا");
return false;
		}

       
        const check_auth_response = await check_auth(token);
        let items = [];
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
        }

  let data = {
	'newUser': false,
	// 'promoCode': code,
	'redeemedPoints': redeemedPoints ? redeemedPoints : 0,
	'paymentType': 'post_paid',
	'patient': favorite_patients,
	'orderFrom': 'polyclinic',
	'type': type,
	'items': [],
    'polyclinic':polyclinic,
	'note':'',
	'totalPrice':null,
	'prescription':[uploadId]


};

// |     promoCode: '',
// 0|doxx  |     redeemedPoints: null,
// 0|doxx  |     location: 'lab',
// 0|doxx  |     paymentType: 'post_paid',
// 0|doxx  |     patient: 987,
// 0|doxx  |     type: 'lab',
// 0|doxx  |     address: 7301,
// 0|doxx  |     items: [],
// 0|doxx  |     prescription: [ 5613 ],
// 0|doxx  |     orderFrom: 'patient',
// 0|doxx  |     note: '',
// 0|doxx  |     totalPrice: null,
// 0|doxx  |     supplier: 5

if(type === "lab"){
 data.supplier = lab;
 if(lab === 0 || lab === undefined || lab === null || lab === ''){
  message.error("اختر موزع اولا");
  return false;
 }
}

if(type === "scan"){
	data.supplier = scan;
	if(scan === 0 || scan === undefined || scan === null || scan === ''){
		message.error("اختر موزع اولا");
		return false;
	   }
   }
   if(type === "pharmacy"){
	data.supplier = null;
	
   }

        await fetch(CONSTANTS.DEFAULT_URL + 'orders', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token

            },
			


            ///api/patients?locale=ar&filters%5Bprofile%5D%5Buser%5D%5BmobileNumber%5D%5B%24contains%5D=%2B201062328373&populate=profile
            body: JSON.stringify({
                "data": data
            })
        }).then(async(response) => {
			response =await response.json();
			if(response.errorMessage){
                message.error(response.errorMessage);
				return false;

			}

			if(response.id){
				message.success("تم الطلب بنجاح");

			}
        }).catch((error) => {
            message.error(error.message);

        });



    }


 
 const [fileList, setFileList] = useState([]);

	const [scans, setScans] = useState([]);
	const [dataLoaded, setDataLoaded] = useState(false);
	const [labs, setLabs] = useState([]);
	const [lab, setLab] = useState(null);
	const [scan, setScan] = useState(null);
	const [polyclinic, setPolyclinicId] = useState(null);

	const [error, setError] = useState(null);
    const navigate = useNavigate();

 useEffect(() => {
    if(token != null ){
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+token
          }
        axios.get(CONSTANTS.DEFAULT_URL+'users/me' , {
            headers: headers
          })
.then(function (response) {
    setPolyclinicId(response.data.polyclinic.id);
  


       if(response.status === 200){
        setData(response.data);


       }else{
        navigate('/'+i18n.language+'/login/doctor');
       }
    

})
.catch(function (error) {
    navigate('/'+i18n.language+'/login');


if(error.response.status === 403){
    navigate('/'+i18n.language+'/login');


}
  console.log(error);
})
// .finally(function () {
//     setLoading(false);
// });
}else{
navigate('/'+i18n.language+'/login');


}
	axios(CONSTANTS.DEFAULT_URL+"suppliers?filters[$and][0][type][$eq]=lab")
	.then((labResponse) => {
		setDataLoaded(true);

		setLabs(labResponse.data.data);
		setLab(labResponse.data.data[0].id);
		
	})
	.catch(setError);

	axios(CONSTANTS.DEFAULT_URL+"suppliers?filters[$and][0][type][$eq]=scan")
	.then((labResponse) => {
		setDataLoaded(true);

		setScans(labResponse.data.data);
		setScan(labResponse.data.data[0].id);
		
	})
	.catch(setError);



    setLoading(true)




	
 }, [])
 const get_patient_image = (data)=>{
	if(data.patient.data!== null){
		return data.patient.profile.maritalStatus;

	}
}

	const get_status = (data)=>{

		if(data.appointment.status !== null){
		return data.appointment.status;
	
		}
	}
		const get_timeSlot = (data)=>{

		if(data.appointment.timeslot !== null){
		return data.appointment.timeslot.start;
	
		}
	}
	const get_fees = (data)=>{

		if(data.totalPrice !== null){
		return data.totalPrice;
	
		}
	}
	const get_payment = (data)=>{

		if(data.paymentStatus !== null){
		return data.paymentStatus;
	
		}
	}
	const get_booking_date = (data)=>{

		if(data.date !== null){
			var date = new Date(data.date);

		return date.toLocaleDateString('ar-EG-u-nu-latn',{weekday: 'long', year: 'numeric', month: 'short', day: 'numeric'});

		}
	}
	const get_order_data = (data)=>{
		var d = [];

	  if(data.appointment.doctor_service !== null){
		  var type =  data.appointment.doctor_service.serviceType;
		  var consultationDuration = data.appointment.doctor_service.consultationDuration
	   if(type === 'telehealth'){
		  d.push(t('TeleHealth'));
		  d.push(" ("+consultationDuration+" "+t("Minute")+")");

	   }else if(type === 'clinic'){
		d.push(t('Clinic'));

	}else if(type === 'homevisit'){
		d.push(t('Home Visit'));

	   }
		  }
		  return d;
  }
  const get_patient_name = (data)=>{
	if(data.patient !== null ){
		if(i18n.language === 'ar' && data.patient.profile.firstname_ar !== null && data.patient.profile.firstname_ar !== ''){
			return (data.patient.profile.firstname_ar + ' '+data.patient.profile.lastname_ar);

		}
        return (data.patient.profile.firstname + ' '+data.patient.profile.lastname);

	}
}

const [progress, setProgress] = useState(0);

const uploadImage = async options => {
    const { onSuccess, onError, file, onProgress } = options;
    console.log(file);

    const fmData = new FormData();
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
            "content-type": "multipart/form-data"
        },
        onUploadProgress: event => {
            const percent = Math.floor((event.loaded / event.total) * 100);
            setProgress(percent);
            if (percent === 100) {
                setTimeout(() => setProgress(0), 1000);
            }
            onProgress({ percent: (event.loaded / event.total) * 100 });
        }
    };
    fmData.append("files", file);
    try {
        const res = await axios.post(
            CONSTANTS.DEFAULT_URL + "upload",
            fmData,
            config
        );

        onSuccess("Ok");
        console.log("server res: ", res.data[0].id);
	
  
        setUploadId(res.data[0].id);
        setPrescriptionText(t('Plus Prescription Which Will Be Calculate From The Pharmacy'));

    } catch (err) {
        console.log("Eroor: ", err);
        const error = new Error("Some error");
        onError({ err });
    }
};
const get_patient_whatsnumber = (data)=>{
	if(data.patient !== null){
        return (data.patient.profile.whatsappNumber);

	}
}

const handleChange = (info) => {
    if (info.file.status === 'uploading') {
        setLoadings(true);
        return;
    }
    if (info.file.status === 'done') {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, (url) => {
            setLoadings(false);
        });
    }
};

const convertToEgyTime = (datatime) => {
	const timeSlotDatetime = new Date(datatime).getTime();
	const newTimeSlot = new Date(timeSlotDatetime + 2 * 60 * 60 * 1000);
	newTimeSlot.setHours(newTimeSlot.getHours() - 2);

	return newTimeSlot.toLocaleTimeString();
  };
  const ConvertToArabicNumbers = (num) => {
	if(i18n.language === 'ar'){
		const arabicNumbers = '\u0660\u0661\u0662\u0663\u0664\u0665\u0666\u0667\u0668\u0669';
		return new String(num).replace(/[0123456789]/g, (d)=>{return arabicNumbers[d]}).replace("PM","مساءا").replace("AM","صباحا");
	   
	}
	return num;
}
const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};

const uploadButton = (
    <div>
        {loading ? <LoadingOutlined /> : <div class="upload-img">
            <div class="change-photo-btn">
                <span><i class="fa fa-upload"></i>{t('Upload Prescription')}</span>
                <input type="file" class="upload" />
            </div>
        </div>}
      
    </div>
);
const [favorite_patients, setFavorite_patients] = useState();

const handleChange3 = (newValue) => {
  setFavorite_patients(newValue);


};

  const [data2, setData2] = useState([]);

const handleSearch3 = (newValue) => {
    fetch2(newValue, setData2,setData);
  };
  return (
<>{load ? (<>
	<div class="col-md-7 col-lg-8 col-xl-9">
		
		<div class="row">
			<div class="col-lg-12">
				<div class="card dash-cards">
		
					<div class="card-body">
						<div class="table-responsive">
                        <div class="form-group col-6">
                                        <label> المريض</label>

                                        <Select
      showSearch
      value={favorite_patients}
      style={{width:'400px'}}
      placeholder={''}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={handleSearch3}
      onChange={handleChange3}
      notFoundContent={null}
      tokenSeparators={[',']}

      options={(data2 || []).map((d) => ({
        value: d.value,
        label: d.text,
      }))}
    />
</div>  
				
																<Divider />
																
					<div class="card">
						<div class="card-body pt-0">
						
				
							{/* {timeslot > 0 ? ( */}
							{/* <>	 */}
						

								
						
								

								
							
										<div class="col-md-5 offset-1">

<Upload
										accept="image/*"
										customRequest={uploadImage}
										onChange={handleChange}
										listType="picture-card"
										className="image-upload-grid"
										defaultFileList={[...fileList]}

									>
										{
											uploadButton
										}
									</Upload>
									</div>
									<div class="row">
                                      <div class="col-md-12">

									  <div class="submit-section proceed-btn text-end">
									<a href='javascript:void(0)' class="btn btn-primary submit-btn" onClick={()=>{AddPharmacyOrder("pharmacy")}} >{t('Order From Pharmacy')} </a>

									</div>
									  </div>
									  <div class="col-md-12">
									  <div class="filter-widget col-md-6">
                                                        <h4>{t('selectSupplier')}</h4>
                                                        <div className='filter-widget'>

                                                            {dataLoaded ? (
                                                                <Select
                                                                    showSearch
                                                                    placeholder={t('selectSupplier')} 
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) =>
                                                                        option.value.toLowerCase().includes(input.toLowerCase())
                                                                    }
                                                                    onChange={(e,key) => setLab(key.value)}
                                                                    style={{ minWidth: 200 }}

                                                                >
																																		

                                                                    {labs.map((labD, i) => {
                                                                        return (
                                                                            <Option value={labD.id}  key={i}>

                                                                                <>{labD.attributes.name}</>

                                                                            </Option>
                                                                        );
                                                                    })}                  </Select>



                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>
                                                    </div>

<div class="submit-section proceed-btn text-end">
<a href='javascript:void(0)' class="btn btn-primary submit-btn" onClick={()=>{AddPharmacyOrder("lab")}} >{t('Order From Lab')} </a>

</div>
</div>
<div class="col-md-12">
<div class="filter-widget col-md-6">
                                                        <h4>{t('selectSupplier')}</h4>
                                                        <div className='filter-widget'>

                                                            {dataLoaded ? (
                                                                <Select
                                                                    showSearch
                                                                    placeholder={t('selectSupplier')}
                                                                    optionFilterProp="children"
                                                                    filterOption={(input, option) =>
                                                                        option.value.toLowerCase().includes(input.toLowerCase())
                                                                    }
                                                                    onChange={(e,key) => setScan(key.value)}
                                                                    style={{ minWidth: 200 }}

                                                                >
                                                                    {scans.map((labD, i) => {
                                                                        return (
                                                                            <Option value={labD.id}  key={i}>

                                                                                <>{labD.attributes.name}</>

                                                                            </Option>
                                                                        );
                                                                    })}                  </Select>



                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>
                                                    </div>
<div class="submit-section proceed-btn text-end">
<a href='javascript:void(0)' class="btn btn-primary submit-btn" onClick={()=>{AddPharmacyOrder("scan")}} >{t('Order From Scan')} </a>

</div>
</div>

									</div>
									
									
							
										

									
						</div>
					
                                    </div>
				
						</div>
					</div>
				</div>
			</div>
		
		</div>
	
	</div>
	</>):(<div class="col-md-7 col-lg-8 col-xl-9"><Skeleton /></div>)}	
	</>
  )
}
