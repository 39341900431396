import React, { useEffect, useState, Suspense } from "react";
import "./i18n";
import i18n from "./i18n";
import { Skeleton, Spin } from "antd";
import './App.css'
import ErrorBoundary from "./compoents/ErrorBoundary";
import useLang from "./compoents/useLanguage";
import { check_auth } from "./helper";
import useToken from "./compoents/useToken";
import axios from "axios";
import { Footer } from "./compoents/Common/footer/Footer";
import { Page404 } from "./compoents/404";
import { Modal , Button} from "antd";



import { BrowserRouter as Router, Routes,  Route, useLocation } from "react-router-dom"

import Header from './compoents/Common/header/Header';

import { Login } from "./compoents/login/login";
import { ResetPassword } from "./compoents/resetPassword/resetPassword";
import { Dashboard } from "./compoents/myclincs/dashboard";
import { useTranslation } from "react-i18next";
import  {AppointmentPage} from "./compoents/myclincs/appointment";
import { PolyclinicAppointments } from "./compoents/myclincs/appointments";
import { PolyclinicClinics } from "./compoents/myclincs/myClinic";
import { ClinicPage } from "./compoents/myclincs/show_clinic";
import { DashboradNavBar } from "./compoents/myclincs/DashboradNavBar";
import Register from "./compoents/register/Register";
import CreateClinic from "./compoents/myclincs/createClinic";
import Otp from "./compoents/otp/Otp";
import { UpdateProfile } from "./compoents/user/update_profile";
import { OrderForPatient } from "./compoents/myclincs/order_for_patient";

function App() {
  const { lang, setLanguage } = useLang();
  const { token, setToken } = useToken();




  const [data, setData] = React.useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [userData, setUserData] = useState(null);
  const [lat , setLat]= useState(null);
  const [lng , setLng]= useState(null);
  const [info , setInfo] = useState();
  const [profilePicture , setProfilePic] = useState(null);
 const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    window.location = "/ar/update-profile";
  };
  const handleCancel = () => {
    window.location = "/ar/update-profile";
  };

  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const handleOk2 = () => {
    window.location.reload();
  };
  const handleCancel2 = () => {
    window.location.reload();
  };


  const [isModalOpen3, setIsModalOpen3] = useState(false);

  const handleOk3 = () => {
    window.location.reload();
  };
  const handleCancel3 = () => {
    window.location.reload();
  };
  i18n.changeLanguage(lang);

  const getUser = async ()=>{
    return await check_auth(token);
   }
  const headers = {
    'Content-Type': 'application/json',
  }
  const checkModal = (email , active , address)=>{
	setInterval(()=>{	
   


    if((email === null  || email === "") && window.location.pathname !== '/ar/update-profile'){

			setIsModalOpen(true);
return false;

		}

    if(address === null && window.location.pathname !== '/ar/update-profile'){
      setIsModalOpen3(true);
      return false;
    }
		if(active !== true && window.location.pathname !== '/ar/update-profile'){

			setIsModalOpen2(true);

			return false;

		}
	
	

	},2000);
}
  useEffect(() => {
  
   
    (async () => {

   await   getUser().then((e)=>{
        setInfo(e);
        console.log(e.data);
        				checkModal(e.data.email , e.data.polyclinic.active , e.data.polyclinic.address)

   

        if(e.data.userInfo){
          setProfilePic(e.data.userInfo.profiles[0]?.profilePicture?.url);
  
        }

        if(token !== null){
          if(e.success === true){
            setUserData(JSON.stringify(e.data));
          }
        }
      }).catch((e)=>{
         return (<>Can't connect</>)
      });

      
     
      
      
    })();
    axios.get("https://api.doxx.care/api/configs?locale="+ localStorage.getItem('lang'), {
			headers: headers
		})
			.then(function (response) {
        console.log(response.data.data);
				setData(response.data.data);
        setDataLoaded(true);
    })
			.catch(function (error) {
				// handle error
				console.log(error);
        setDataLoaded(false);


			});
  
  }, []);

  return  (
  // <React.StrictMode>
  // <React.Fragment>
  <ErrorBoundary>

    {dataLoaded ?  
     <>
  

 
     <div class="main-wrapper">
      	<Modal title="" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}          footer={[
     <Button key="back" onClick={handleCancel}>
     تحديث
   </Button>
        ]}>
        <h1>{'من فضلك املأ البريد الألكتروني'}</h1>
  
      </Modal>
	  <Modal title="" open={isModalOpen2} onOk={handleOk2} onCancel={handleCancel2}          footer={[
     ,
        ]}>
        <h1>تم استلام طلبك بنجاح و سيتم التواصل معك خلال ثلاثة أيام عمل لتفعيل حسابك </h1>
  
      </Modal>

      <Modal title="" open={isModalOpen3} onOk={handleOk3} onCancel={handleCancel3}          footer={[
          <Button key="back" onClick={handleCancel}>
          تحديث
        </Button>
        ]}>
        <h1>  من فضلك ادخل تفاصيل عنوان المركز الصحي </h1>
  
      </Modal>
    <Router>
       <Header userData={userData}  token={token} setLanguage={setLanguage}  phoneD={data.customerSupportInfo.doctorPhoneNumber?data.customerSupportInfo.doctorPhoneNumber:''} phoneP={data.customerSupportInfo.patientPhoneNumber?data.customerSupportInfo.patientPhoneNumber : ''}  email={data.config.patient.customerSupportInfo.email}/>
       <div class="container-fluid">
 
 <div class="row">
   <div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
     
 
    {token !== null && info != null ? (<><DashboradNavBar info={info} setToken={setToken} profilePicture={profilePicture} /></>) : (<></>)}   
   
   </div>
       <Routes>
       <Route  path='/' exact element={<Dashboard data={data} token={token} userData={userData}  />} />
       <Route  path='/:lang' exact element={<Dashboard data={data} token={token} userData={userData}  />} />

       <Route  path='/:lang/login' exact element={<Login data={data} token={token} setToken={setToken}   userData={userData} />} />
       <Route  path='/:lang/reset-password' exact element={<ResetPassword data={data} token={token} setToken={setToken}   userData={userData} />} />
       <Route  path='/:lang/profile' exact element={<UpdateProfile data={data} token={token} setToken={setToken}   userData={userData} />} />
       <Route  path='/:lang/appointment/:id'  element={<AppointmentPage data={data} token={token} setToken={setToken}   userData={userData} />} />
       <Route  path='/:lang/clinic/:id'  element={<ClinicPage data={data} token={token} setToken={setToken}   userData={userData} />} />
       <Route  path='/:lang/orderForPatient'  element={<OrderForPatient data={data} token={token} setToken={setToken}   userData={userData} />} />

       <Route  path='/:lang/appointments'  element={<PolyclinicAppointments data={data} token={token} setToken={setToken}   userData={userData} />} />
       <Route  path='/:lang/clinics'  element={<PolyclinicClinics data={data} token={token} setToken={setToken}   userData={userData} />} />
       <Route  path='/:lang/register' exact element={<Register data={data} token={token} setToken={setToken}   userData={userData} />} />
       <Route  path='/:lang/createClinic' exact element={<CreateClinic data={data} token={token} setToken={setToken}   userData={userData} />} />
       <Route  path='/:lang/otp' exact element={<Otp data={data} token={token} setToken={setToken}   userData={userData} />} />

       <Route  path='/:lang/update-profile' exact element={<UpdateProfile data={data} token={token} setToken={setToken}  userData={userData} />} />

      {/*    <Route exact path='/blog' component={Blog} />
         <Route exact path='/pricing' component={Pricing} />
         <Route exact path='/contact' component={Contact} />  */}
       </Routes>
       {/* <Tip image={data.config.adminConfig[0].attributes.icon.data.attributes.formats.medium.url} 
       height={data.config.adminConfig[0].attributes.icon.data.attributes.height} 
        width={data.config.adminConfig[0].attributes.icon.data.attributes.width}/> */}

       <Footer  />
       </div>

</div>
     </Router>
     </div>
    </>
  
  :  <>
<Skeleton size={'large'} active />
      </>}
      </ErrorBoundary>

    // </React.Fragment>
    // </React.StrictMode>
  )
}
export default App;
